<!--
 * @Author: awenyjw
 * @Date: 2023-04-10 13:30:28
 * @LastEditors: awenyjw
 * @LastEditTime: 2023-06-28 10:01:30
 * @FilePath: \hc-smartSupervision\src\views\system\account\settings\Custom.vue
 * @Description: 
 * 
-->
<script>
import { colorList } from '@/components/SettingDrawer/settingConfig'
import ASwitch from 'ant-design-vue/es/switch'
import AList from 'ant-design-vue/es/list'
import AListItem from 'ant-design-vue/es/list/Item'
import { mixin } from '@/utils/mixin'
import Vue from 'Vue'
const Meta = AListItem.Meta

export default {
  components: {
    AListItem,
    AList,
    ASwitch,
    Meta
  },
  mixins: [mixin],
  data() {
    return {
      fontS: 'primary'
    }
  },
  filters: {
    themeFilter(theme) {
      const themeMap = {
        dark: '暗色',
        light: '白色'
      }
      return themeMap[theme]
    }
  },
  methods: {
    colorFilter(color) {
      const c = colorList.find(o => o.color === color)
      return c && c.key
    },

    onChange(checked) {
      if (checked) {
        this.$store.dispatch('ToggleTheme', 'dark')
      } else {
        this.$store.dispatch('ToggleTheme', 'light')
      }
    },
    // changeFontS(checked) {
    //   console.log(checked, ':checked')
    //   let zoomLevel = null
    //   if (checked) {
    //     zoomLevel = 0.5 // 缩放比例为150%
    //   } else {
    //     zoomLevel = 1 // 缩放比例为100%
    //   }
    //   const width = window.screen.width * zoomLevel
    //   const height = window.screen.height * zoomLevel
    //   // window.resizeTo(width, height)
    //   Vue.prototype.width = width
    //   Vue.prototype.height = height
    // }
  },
  render() {
    return (
      <AList itemLayout="horizontal">
        <AListItem>
          <Meta>
            <a slot="title">风格配色</a>
            <span slot="description">整体风格配色设置</span>
          </Meta>
          <div slot="actions">
            <ASwitch
              checkedChildren="暗色"
              unCheckedChildren="白色"
              defaultChecked={(this.navTheme === 'dark' && true) || false}
              onChange={this.onChange}
            />
          </div>
        </AListItem>
        {/* <AListItem>
          <Meta>
            <a slot="title">字体大小设置</a>
            <span slot="description">整体字体大小设置</span>
          </Meta>
          <div slot="actions">
            <ASwitch
              checkedChildren="关怀"
              unCheckedChildren="普通"
              defaultChecked={this.fontS === 'primary' ? false : true}
              onChange={this.changeFontS}
            />
          </div>
        </AListItem> */}
        {/* <AListItem>
          <Meta>
            <a slot="title">主题色</a>
            <span slot="description">
                页面风格配色： <a domPropsInnerHTML={ this.colorFilter(this.primaryColor) }/>
            </span>
          </Meta>
        </AListItem> */}
      </AList>
    )
  }
}
</script>

<style scoped></style>
